<template>
  <ListRow>
    <!-- 완료여부 -->
    <td>{{ rowData.completeStatusName }}</td>
    <!-- 발주번호 -->
    <td>
      <button
        class="link_subject"
        :data-ellip="rowData.partnerName || ''"
        @click="$windowOpen(`${$routerPath.ORDER_VIEW}/${rowData.orderCid}`)"
      >
        {{ rowData.orderCid || '' }}
      </button>
    </td>
    <!-- 발주명 -->
    <td class="td_ellip align_left" :data-ellip="`${rowData.orderTitle}`">
      {{ rowData.orderTitle }}
    </td>
    <!-- 프로젝트명 -->
    <td
      class="td_ellip align_left"
      :data-ellip="`[${rowData.projectCode}] ${rowData.projectName}`"
      v-html="rowData.projectName"
    ></td>
    <!-- 발주일자 -->
    <td>{{ rowData.orderDate | dateStringFormat }}</td>
    <!-- 파트너사 -->
    <td class="td_ellip align_left">
      <button
        class="link_subject"
        :data-ellip="rowData.partnerName || ''"
        @click="$windowOpen(`${$routerPath.PARTNER_VIEW}/${rowData.partner}`)"
      >
        {{ rowData.partnerName || '' }}
      </button>
    </td>
    <!-- 검수담당자 -->
    <td>{{ `${rowData.inspectName}(${rowData.inspect})` }}</td>
    <!-- 통화 -->
    <td>{{ rowData.currency || 'KRW' }}</td>
    <!-- 발주금액 -->
    <td class="align_right">{{ rowData.contractPrice | currency }}</td>
    <!-- 요청금액 -->
    <td class="align_right">{{ rowData.requestPrice | currency }}</td>
    <!-- 제안단계 견적가 -->
    <td class="align_right">{{ rowData.estimatePrice | currency }}</td>
    <!-- 검수잔액 -->
    <td class="align_right">{{ rowData.inspectBalancePrice | currency }}</td>
    <!-- 절감액(요청대비) -->
    <td class="align_right">{{ rowData.requestSavingsPrice | currency }}</td>
    <!-- 절감율(요청대비) -->
    <td class="align_right">{{ rowData.requestSavingsRate | currency }}</td>
    <!-- 절감액(제안대비) -->
    <td class="align_right">{{ rowData.proposalSavingsPrice | currency }}</td>
    <!-- 절감율(제안대비) -->
    <td class="align_right">{{ rowData.proposalSavingsRate | currency }}</td>

    <!-- 중도종결사유 -->
    <td class="align_left">
      <span v-if="!rowData.changeReason">-</span>
      <template v-else>
        <button
          class="link_subject td_ellip"
          v-click-outside="onClickChangeReasonToggleClose"
          @click="onClickChangeReasonToggleOpen"
        >
          {{ rowData.changeReason }}
        </button>
        <Dialouge :isToggle="isChangeReasonToggle">
          <template v-slot:dialogueBody> {{ rowData.changeReason }}</template>
        </Dialouge>
      </template>
    </td>
    <!-- 계약서번호 -->
    <td>
      <button
        class="link_subject"
        :data-ellip="rowData.partnerName || ''"
        @click="$windowOpen(`${$routerPath.CONTRACT_VIEW}/${rowData.contractId}`)"
      >
        {{ rowData.contractCid || '' }}
      </button>
    </td>
    <!-- 계약시작일 -->
    <td>{{ rowData.contractStartDate | dateStringFormat }}</td>
    <!-- 계약종료일 -->
    <td>{{ rowData.contractEndDate | dateStringFormat }}</td>
    <!-- 요청부서 -->
    <td>{{ rowData.requestDeptName }}</td>
    <!-- 구매담당자 -->
    <td>{{ `${rowData.purchaseName}(${rowData.purchase})` }}</td>
  </ListRow>
</template>

<script>
import ListRow from '@/components/shared/ListRow';
import ListLineMixin from '@/mixins/ListLineMixin';
import Dialouge from '@/components/common/dialouge/Dialouge.vue';

export default {
  name: 'OrderCaseListLine',
  components: {
    ListRow,
    Dialouge,
  },
  mixins: [ListLineMixin],
  props: {
    rowData: Object,
  },
  data() {
    return {
      isDisagreeReasonToggle: false,
      isChangeReasonToggle: false,
    };
  },
  computed: {},
  methods: {
    onClickDisagreeReasonToggleOpen() {
      this.isDisagreeReasonToggle = true;
    },
    onClickDisagreeReasonToggleClose() {
      if (!this.isDisagreeReasonToggle) {
        return;
      }
      this.isDisagreeReasonToggle = false;
    },
    onClickChangeReasonToggleOpen() {
      this.isChangeReasonToggle = true;
    },
    onClickChangeReasonToggleClose() {
      if (!this.isChangeReasonToggle) {
        return;
      }
      this.isChangeReasonToggle = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .dialogue_comm {
    position: relative;
  }
  .box_dialogue {
    position: absolute;
    left: 0;
    top: 0;
    white-space: pre-line;
  }
}
</style>
