<template>
  <FilterBarBase
    :excelBtn="$isAdmin ? true : false"
    @onClickSearch="onClickSearch"
    @onClickExcelBtn="onClickExcelBtn"
    @onClickReset="onClickReset"
  >
    <dl class="w152">
      <dt>진행상태</dt>
      <dd>
        <SelectBox :dataList="statusList" :isDisabled="isDisabledStatus" :value.sync="statusId" />
      </dd>
    </dl>
    <dl>
      <dt>발주기간</dt>
      <dd>
        <DateRangeQuickSelect :dates.sync="dates" />
      </dd>
      <dt>검색어</dt>
      <dd>
        <div class="group_form">
          <SelectBox
            style="width: 240px"
            :dataList="keywordDatas"
            :isDisabled="isDisabledStatus"
            :value.sync="keywordSelectedId"
          />
          <Input class="w437" :value.sync="keywordInputText" @onEnterKey="onClickSearch" />
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from '@/components/shared/filterBar/FilterBarBase';
import Input from '@/components/common/input/Input';
import Radio from '@/components/common/radio/Radio';
import SelectBox from '@/components/common/selectBox/SelectBox';
import CategoryGroupIncludeData from '@/components/common/selectBox/CategoryGroupIncludeData';
import CheckboxOnlyOne from '@/components/common/checkbox/CheckboxOnlyOne';
import DateRangeQuickSelect from '@/components/common/calendar/DateRangeQuickSelect';

import FilterBarMixin from '@/mixins/FilterBarMixin';
import DatePicker from '@/components/common/calendar/DatePicker';
import { dateToString } from '@/utils/dateUtils';

export default {
  name: 'OrderCaseFilterBar',
  components: {
    DatePicker,
    FilterBarBase,
    Input,
    Radio,
    SelectBox,
    DateRangeQuickSelect,
    CategoryGroupIncludeData,
    CheckboxOnlyOne,
  },
  mixins: [FilterBarMixin],
  props: {},
  data() {
    return {
      orderDate: '',
    };
  },
  methods: {
    getFilterbarData() {
      const isValidDates = this.dates && this.dates.length === 2;

      const ret = {
        completeStatus: this.stateId || this.statusId,
        keywordSelectedId: this.keywordSelectedId,
        keywordInputText: this.keywordInputText.trim(),
        startDt: isValidDates ? dateToString(this.dates[0]) : null,
        endDt: isValidDates ? dateToString(this.dates[1]) : null,

        category0: this.category0,
        category1: this.category1,
        category2: this.category2,

        assetTypeId: this.assetTypeId,
      };

      return ret;
    },
    onClickSearch() {
      let obj = this.getFilterbarData();

      this.$emit('onClickSearch', obj);
    },
    onClickReset() {
      this.onClickResetFilterBar();
    },
    onClickExcelBtn() {
      const isDatesSelected = this.dates && this.dates.length === 2;

      this.$emit('onClickExcelBtn', isDatesSelected);
    },
  },
};
</script>
