import { render, staticRenderFns } from "./OrderCaseFilterBar.vue?vue&type=template&id=4be2108e&"
import script from "./OrderCaseFilterBar.vue?vue&type=script&lang=js&"
export * from "./OrderCaseFilterBar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../service_standard_web/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports