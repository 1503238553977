<template>
  <div class="dialogue_comm" @mousemove="checkHoverTip">
    <div class="dialogue_head">
      <slot name="dialogueHead" />
    </div>
    <div class="box_dialogue" :style="styleCss">
      <span class="ico_purchase ico_dialouge" />
      <slot name="dialogueBody" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Dialouge",
  props: {
    isToggle: Boolean,
  },
  data() {
    return {
      tooltipSet: {},
    };
  },
  computed: {
    styleCss() {
      let style = "";
      if (this.isToggle) {
        style = "display:block;";
      } else {
        if (!this.tooltipSet.mouseX) return;
        style = "left:" + this.tooltipSet.mouseX + "px;top:" + this.tooltipSet.mouseY + "px";
      }
      return style;
    },
  },
  methods: {
    checkHoverTip(e) {
      if (this.isToggle) {
        return;
      }
      const result = { mouseX: e.clientX, mouseY: e.clientY };
      this.tooltipSet = result;
    },
  },
};
</script>
<style lang="scss" scpoed>
/* dialogue */
.dialogue_comm {
  display: inline-block;
  vertical-align: top;
}
.dialogue_head .txt_dialogue {
  display: inline-block;
  text-align: center;
  vertical-align: top;
}

.box_dialogue {
  display: none;
  position: fixed;
  z-index: 100;
  overflow-y: auto;
  width: 206px;
  max-height: 245px;
  margin: 0 0 0 20px;
  padding: 12px 16px;
  border: 1px solid #222;
  font-size: 12px;
  background-color: #fff;
  color: #555;
  text-align: left;
}
.box_dialogue .ico_dialouge {
  position: absolute;
  top: 0;
  left: 0;
}
.box_dialogue .tit_dialogue {
  display: block;

  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #222;
}
.box_dialogue .info_dialogue {
  margin: 8px 0;
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
.box_dialogue .info_dialogue dt {
  float: left;
  clear: left;
  display: block;
  width: 60px;
}
.box_dialogue .info_dialogue dd {
  float: left;
  display: block;
  width: calc(100% - 60px);
  box-sizing: border-box;
  padding-left: 2px;
}
.box_dialogue .desc_dialogue {
  padding-top: 8px;
  border-top: 1px solid #e6e6e6;
}

.dialogue_head:hover + .box_dialogue {
  display: inline-block;
}
</style>
