<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">발주건별정보</h2>
      </div>
      <div class="body_section">
        <OrderCaseFilterBar
          ref="filterBar"
          :statusList="statusList"
          :defaultStatusId="defaultStatusId"
          :keywordDatas="keywordList"
          :defaultDates="defaultDates"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          @onClickSearch="onClickSearch"
          @onClickExcelBtn="onClickExcelBtn"
        />

        <TableHead>
          <template v-slot:headRight>
            <SelectBoxPageRange
              :currentMaxRowCount.sync="size"
              @update:currentMaxRowCount="onChangeRowCount"
            />
          </template>
        </TableHead>
        <ListPage
          :list="dataList"
          :isHorizontalScroll="true"
          :scrollWidth="'2700px'"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <!-- 완료여부 -->
            <col style="width: 100px" />
            <!-- 발주번호 -->
            <col style="width: 120px" />
            <!-- 발주명 -->
            <col />
            <!-- 프로젝트명 -->
            <col style="width: 220px" />
            <!-- 발주일자 -->
            <col style="width: 80px" />
            <!-- 파트너사 -->
            <col style="width: 100px" />
            <!-- 검수담당자 -->
            <col style="width: 160px" />
            <!-- 통화 -->
            <col style="width: 60px" />
            <!-- 발주금액 -->
            <col style="width: 100px" />
            <!-- 요청금액 -->
            <col style="width: 100px" />
            <!-- 제안단계 견적가 -->
            <col style="width: 100px" />
            <!-- 검수잔액 -->
            <col style="width: 100px" />
            <!-- 절감액(요청대비) -->
            <col style="width: 100px" />
            <!-- 절감율(요청대비) -->
            <col style="width: 80px" />
            <!-- 절감액(제안대비) -->
            <col style="width: 100px" />
            <!-- 절감율(제안대비) -->
            <col style="width: 80px" />

            <!-- 중도종결사유 -->
            <col style="width: 160px" />
            <!-- 계약서번호 -->
            <col style="width: 130px" />
            <!-- 계약시작일 -->
            <col style="width: 80px" />
            <!-- 계약종료일 -->
            <col style="width: 80px" />
            <!-- 요청부서 -->
            <col style="width: 120px" />
            <!-- 구매담당자 -->
            <col style="width: 160px" />
          </template>
          <template v-slot:tr>
            <th>완료여부</th>
            <th>발주번호</th>
            <th>발주명</th>
            <th>프로젝트명</th>
            <th>발주일자</th>
            <th>파트너사</th>
            <th>검수담당자</th>
            <th>통화</th>
            <th>발주금액</th>
            <th>요청금액</th>
            <th>제안단계 견적가</th>
            <th>검수잔액</th>
            <th>절감액<br />(요청대비)</th>
            <th>절감율<br />(요청대비)</th>
            <th>절감액<br />(제안대비)</th>
            <th>절감율<br />(제안대비)</th>
            <th>중도종결사유</th>
            <th>계약서번호</th>
            <th>계약시작일</th>
            <th>계약종료일</th>
            <th>요청부서</th>
            <th>구매담당자</th>
          </template>
          <template v-slot:row="slotProps">
            <OrderCaseListLine :rowData="slotProps.rowData" />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="21" class="td_empty">통계 데이터가 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
  </PageWithLayout>
</template>
<script>
import { mapState } from 'vuex';
import ApiService from '@/services/ApiService';
import PageWithLayout from '@/components/layout/PageWithLayout';
import ListPage from '@/components/shared/ListPage';
import SelectBoxPageRange from '@/components/common/selectBox/SelectBoxPageRange';
import TableHead from '@/components/shared/TableHead';
import PageMixin from '@/mixins/PageMixin';
import ListPageMixin from '@/mixins/ListPageMixin';
import OrderCaseFilterBar from '@LIG/components/admin/statistics/list/orderCase/OrderCaseFilterBar';
import OrderCaseListLine from '@LIG/components/admin/statistics/list/orderCase/OrderCaseListLine';

import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from '@/store/modules/progressbar/action';
import { downloadFile } from '@/utils/fileUtils';
import { makeQueryStringByObject } from '@/utils/urlUtils';
import { GET_ORDERCASE_LIST_ACTION } from '@LIG/store/modules/statistics/action';

export default {
  name: 'OrderCase',
  components: {
    OrderCaseFilterBar,
    OrderCaseListLine,
    ListPage,
    SelectBoxPageRange,
    TableHead,
    PageWithLayout,
  },
  mixins: [PageMixin, ListPageMixin],
  data() {
    return {
      API_PATH: `${this.$apiPath.STATISTICS_ORDER}`,
      defaultStatusId: '',
      defaultKeywordSelectedId: 'projectName',
    };
  },
  computed: {
    ...mapState({
      statusList: (state) => state.statistics.orderCaseStatuslist,
      keywordList: (state) => state.statistics.orderCaseKeywordlist,
    }),
  },
  created() {
    const params = this.checkUrlQuery();
    this.$store.dispatch(GET_ORDERCASE_LIST_ACTION).then(() => {
      this.getDataInit(params);
    });
  },
  methods: {
    checkUrlQuery() {
      const { query } = this.$route;
      const objPaging = this.checkQueryPaging(query);
      const objKeyword = this.checkQueryKeywords(query, this.keywordList);
      const objStatus = this.checkQueryStatus(query);
      // 날짜 검색이 좀 다름.
      const objDates = this.checkQueryDates(query, 'startDate', 'endDate');
      return Object.assign({}, objPaging, objKeyword, objDates, objStatus);
    },

    checkQueryStatus(query) {
      const { completeStatus } = query;

      if (!completeStatus) return {};

      this.defaultStatusId = completeStatus;

      return { completeStatus: completeStatus };
    },
    getFilterbarParams(objData) {
      const {
        keywordSelectedId,
        keywordInputText,
        startDt,
        endDt,
        completeStatus,
        dateSelectedId,
      } = objData;
      let params = {};
      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }
      if (startDt && endDt) {
        params['startDate'] = startDt;
        params['endDate'] = endDt;
      }
      if (completeStatus) {
        params.completeStatus = completeStatus;
      }
      return params;
    },
    async onClickExcelBtn() {
      this.$store.dispatch(PROGRESS_START_ACTION);
      const path = `${this.$apiPath.EXCEL}/orderSta`;
      const filterBar = this.$refs.filterBar;
      if (!filterBar) {
        this.$store.dispatch(PROGRESS_END_ACTION);
        return;
      }
      const objFilterBarData = filterBar.getCommonFilterbarData();
      const params = this.getFilterbarParams(objFilterBarData, true);
      const result = await ApiService.shared.get(`${path}${makeQueryStringByObject(params)}`, {
        isNeedAuth: true,
        responseType: 'arraybuffer',
      });

      this.$store.dispatch(PROGRESS_END_ACTION);

      downloadFile(result, `발주건별정보_${this.moment().format('YYYYMMDD')}.xlsx`);
    },
  },
};
</script>
